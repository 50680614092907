@tailwind base;
@tailwind components;
@tailwind utilities;


.logo {
  width: 228px;
  z-index: 100;
}

@media (max-width: 768px) {
  .dual-column-container {
    flex-direction: column;
    height: auto;
    overflow: visible;
    width: 100%;
  }

  .btn {
    width: 100%;
    height: 50vh;
  }

  .logo {
    width: 160px;
    z-index: 100;
  }
}